import { getPaymentMethods } from '../../../actions/billing/paymentMethods';
import { trackPaymentMethodChangeFinished } from '../../../helpers/switch/analytics';
import { PAYMENT_ERROR_GROUPS, PAYMENT_ERROR_TYPES } from '../constants/paymentErrors';
import updatePaymentMethod from '../helpers/updatePaymentMethod';
import { PAYMENT_STAGES } from '../PaymentContextProvider';

const handleSuccessfulPurchase = async (paymentContext, dispatch) => {
  const { clearPaymentErrors, goToPaymentStage, setPaymentChangeActive } = paymentContext;

  await getPaymentMethods()(dispatch);

  clearPaymentErrors();
  setPaymentChangeActive(false);
  goToPaymentStage(PAYMENT_STAGES.PAYMENT_METHOD_UPDATED);
};

const handleUnsuccessfulPurchase = paymentContext => {
  const { raisePaymentError, restartPaymentFlow } = paymentContext;

  raisePaymentError({
    message: 'UNSUCCESSFUL_PURCHASE',
    group: PAYMENT_ERROR_GROUPS.METHOD_CHANGE,
    type: PAYMENT_ERROR_TYPES.METHOD_CHANGE.UNSUCCESSFUL,
  });
  restartPaymentFlow();
};

const paymentChangeAuthorizedTransition = (paymentContext, authorizationOutputToken) => async dispatch => {
  const { tokens, paymentChangeType } = paymentContext;

  const { complete } = await dispatch(
    updatePaymentMethod({
      paymentMethodToken: tokens.paymentMethod,
      authorizationToken: authorizationOutputToken,
    })
  );

  const updateStatus = complete ? 'payment_updated' : 'payment_update_failed';

  if (complete) {
    trackPaymentMethodChangeFinished({ updateStatus, attemptedUpdateMethod: paymentChangeType });
    handleSuccessfulPurchase(paymentContext, dispatch);
  } else {
    trackPaymentMethodChangeFinished({
      updateStatus,
      attemptedUpdateMethod: paymentChangeType,
    });
    handleUnsuccessfulPurchase(paymentContext);
  }
};

export default paymentChangeAuthorizedTransition;
