export const PAYMENT_ERROR_GROUPS = {
  TOKENIZATION: 'TOKENIZATION_ERROR',
  AUTHORIZATION: 'AUTHORIZATION_ERROR',
  FULFILLMENT: 'FULFILLMENT_ERROR',
  METHOD_CHANGE: 'METHOD_CHANGE',
};

export const PAYMENT_ERROR_TYPES = {
  TOKENIZATION: {
    GENERATING_PAYMENT_TOKEN: 'GENERATING_PAYMENT_TOKEN',
    INITIATING_PURCHASE: 'INITIATING_PURCHASE',
  },
  AUTHORIZATION: {
    UNSUCCESSFUL_3DS: 'UNSUCCESSFUL_3DS',
    UNSUCCESSFUL_PURCHASE_RETRY: 'UNSUCCESSFUL_PURCHASE_RETRY',
  },
  FULFILLMENT: {
    INITIATING_FULFILLMENT: 'INITIATING_FULFILLMENT',
  },
  METHOD_CHANGE: {
    UNSUCCESSFUL: 'CHANGING_PAYMENT_METHOD',
  },
};

export const ERROR_DEBUG_MESSAGES = {
  PAYMENT: {
    NON_3DS_ERROR: 'ERROR_NOT_3DS_TRIGGER_RESET',
  },
};
