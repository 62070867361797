import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { replace, push } from 'connected-react-router';
import { submit } from 'redux-form';
import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import isEqual from 'lodash/isEqual';
import Analytics, { MilestoneInputEvent, OptimisationInputEvent } from '@experian-uk/web-common-analytics';
import InViewComponent from '../../components/InView';
import { addPaymentMethod, getPaymentMethods } from '../../actions/billing/paymentMethods';

import { getDynamicString, switchInfo } from '../../helpers';
import getBreachDeferredStatus from '../../helpers/subscription/getBreachDeferredStatus';
import getFormattedFreeTrialData from '../../helpers/getFormattedFreeTrialData';
import CardSaveError from '../../exceptions/cardSaveError';
import getDefermentInfo from '../../helpers/getDefermentInfo';

import AddCardChallenge from '../../components/subscriptions/AddCardChallenge';
import Container from '../../components/Container';
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import DeactivateAccount from '../../components/subscriptions/DeactivateAccount';
import Errors from '../../components/Alerts/Errors';
import TopContainer from '../../components/TopContainer';
import BreachUpsell from '../../components/BreachUpsell/BreachUpsell';
import BreachRetentionAlert from '../../components/Alerts/BreachAlerts/BreachRetentionAlert';
import BreachDeferredSubscriptionBanner from '../../components/Alerts/BreachAlerts/BreachDeferredSubscriptionBanner';

import { getAvailableOffers } from '../../reducers/offers';
import { selectPage } from '../../reducers/uiState';
import { getSplitStatus } from '../../reducers/selectors';
import { commonEcdSchema, subscriptionSchema } from '../../schemas';
import { errorMessages, products, subscriptionActions, splitsList } from '../../constants';
import resetChallenge from '../../actions/billing/resetChallenge';
import uiChangeState from '../../actions/uiChangeState';
import updateSession from '../../actions/session/update';
import syncSession from '../../actions/session/sync';
import selectOffer from '../../actions/offers/select';
import getSelections from '../../actions/conductrics/getSelections';
import addEvent from '../../actions/ecd/addEvent';
import initializeEcd from '../../actions/ecd/initialize';
import abTests from '../../constants/abtests';

import ProductPanels from '../../components/ProductPanels';
import { Heading } from './index.styles';
import isServer from '../../helpers/isServer';
import sendReward from '../../actions/conductrics/sendReward';
import breachRetainingExistingCustomersCheck from '../../helpers/breachRetainingExistingCustomersCheck';
import getCommonECDData from '../../reducers/selectors/getCommonECDData';
import AccountPaymentInformationSection from '../../components/SubscriptionAndPaymentMethods';
import BreachExtensionSuccess from '../../components/Alerts/BreachAlerts/BreachExtensionSuccess';
import BILLING_PROVIDERS from '../../constants/billingProviders';
import PaymentOrchestrator from '../../components/PaymentOrchestrator';

const newCardFormName = 'productMovement/newCard';

export const homeAATest = conductrics => {
  if (isServer() || !conductrics?.sels) return;

  const { apiCode, name } = abTests.PRODMOVE_AA_TEST_HP;

  if (window.$ECD2?.some(existingEcdEvent => existingEcdEvent?.optimisation_test_triggered?.test_name === name)) return;

  const testVariant = conductrics.sels[apiCode];

  if (['A', 'B'].includes(testVariant)) {
    const event = OptimisationInputEvent.fromObject({
      testName: name,
      testVariant,
      testArea: 'Product Movement',
    });
    if (window.$ECD2) {
      Analytics.publishOnce(event, apiCode);
    }
  }
};

const protectOptimisationECD = isProtectWebRolloutSplit => {
  if (['true', 'protect_control'].includes(isProtectWebRolloutSplit)) {
    const protectTestMap = { true: 'Test', protect_control: 'Control' };

    Analytics.publishOnce(
      OptimisationInputEvent.fromObject({
        testName: 'Protect MVP test',
        testArea: 'Protect',
        testVariant: protectTestMap[isProtectWebRolloutSplit],
      })
    );
  }
};

@connect(
  state => ({
    offers: getAvailableOffers(state.offers, state.subscriptions),
    commonEcdData: getCommonECDData(state),
    currentSubscription: state.subscriptions.current,
    currentOffer: state.offers.currentOffer,
    customer: state.auth.currentUser,
    billing: state.billing,
    conductrics: state.conductrics,
    loading: state.billing.loading,
    newCardForm: state.form[newCardFormName],
    billingDate: state.billing.nextBillingDate,
    newBillingProvider: state.billing.billingProvider === BILLING_PROVIDERS.NEW,
    showCardForm: selectPage(state.uiState, window.location.pathname).showCardForm,
    showChangePaymentMethod: selectPage(state.uiState, window.location.pathname).showChangePaymentMethod,
    showChangePaymentSuccess: selectPage(state.uiState, window.location.pathname).showChangePaymentSuccess,
    productDetails: state.productDetails,
    isPaymentDetailsView:
      'query' in state.router.location &&
      'paymentInformation' in state.router.location.query &&
      state.router.location.query.paymentInformation === '1',
    query: state.router.location.query,
    shouldShowBreachRetainingUI: breachRetainingExistingCustomersCheck(
      state.split,
      state.subscriptions?.current?.family
    ),
    splits: getSplitStatus(state.split, [splitsList.applePay, splitsList.protectWebRollout]),
    breachExtensionVoucher: state.router.location.query.breachExtensionVoucher === 'true',
  }),
  dispatch =>
    bindActionCreators(
      {
        uiChangeState,
        addPaymentMethod,
        getPaymentMethods,
        submit,
        push,
        replace,
        resetChallenge,
        updateSession,
        syncSession,
        selectOffer,
        getSelections,
        addEvent,
        initializeEcd,
        sendReward,
      },
      dispatch
    )
)
export default class Home extends React.Component {
  static onlyCardErrors = err => err.type === CardSaveError.errorType;

  static withoutCardErrors = err => !Home.onlyCardErrors(err);

  static propTypes = {
    commonEcdData: PropTypes.shape(commonEcdSchema),
    uiChangeState: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    addPaymentMethod: PropTypes.func.isRequired,
    getPaymentMethods: PropTypes.func.isRequired,
    offers: PropTypes.arrayOf(PropTypes.shape({})),
    currentSubscription: PropTypes.shape(subscriptionSchema),
    currentOffer: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    showCardForm: PropTypes.bool,
    newCardForm: PropTypes.shape(),
    billing: PropTypes.shape({
      authorized: PropTypes.bool,
      cardSaved: PropTypes.bool,
      challengeUrl: PropTypes.string,
      fetchFailed: PropTypes.bool,
      endDate: PropTypes.string,
      freeTrialTerms: PropTypes.shape({
        remaining: PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        }),
      }),
      nextBillingDate: PropTypes.string,
    }).isRequired,
    newBillingProvider: PropTypes.bool,
    conductrics: PropTypes.shape({
      error: PropTypes.bool,
      fetching: PropTypes.bool,
      sels: PropTypes.shape({}),
    }),
    customer: PropTypes.shape({
      customerNumber: PropTypes.string,
      customerId: PropTypes.string,
    }),
    loading: PropTypes.bool,
    productDetails: PropTypes.shape({
      isBreach: PropTypes.bool,
      productIdentifier: PropTypes.string,
    }),
    isPaymentDetailsView: PropTypes.bool,
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
    resetChallenge: PropTypes.func.isRequired,
    syncSession: PropTypes.func.isRequired,
    selectOffer: PropTypes.func.isRequired,
    updateSession: PropTypes.func.isRequired,
    getSelections: PropTypes.func.isRequired,
    addEvent: PropTypes.func,
    initializeEcd: PropTypes.func,
    sendReward: PropTypes.func.isRequired,
    shouldShowBreachRetainingUI: PropTypes.bool,
    splits: PropTypes.shape({
      applePay: PropTypes.bool,
      protectWebRollout: PropTypes.string,
    }).isRequired,
    breachExtensionVoucher: PropTypes.bool,
  };

  static defaultProps = {
    commonEcdData: null,
    currentSubscription: {},
    currentOffer: {},
    offers: [],
    showCardForm: false,
    newCardForm: {
      syncErrors: {},
    },
    conductrics: {
      error: null,
      fetching: false,
      sels: {},
    },
    customer: {},
    loading: false,
    productDetails: {
      isBreach: false,
      productIdentifier: null,
    },
    isPaymentDetailsView: false,
    initializeEcd: () => ({}),
    addEvent: () => ({}),
    shouldShowBreachRetainingUI: false,
    breachExtensionVoucher: false,
    newBillingProvider: false,
  };

  state = {
    scrolling: false,
    isRedirecting: false,
    isECDInitialized: false,
  };

  constructor(props) {
    super(props);
    this.paymentDetailsSectionRef = React.createRef();
  }

  async componentDidMount() {
    this.initializeAnalytics();

    if (this.props.isPaymentDetailsView) {
      this.scrollToPaymentInfo();
    }

    window.onpopstate = () => {
      // Clear challenge and polling when navigate away
      this.props.resetChallenge();
    };

    homeAATest(this.props.conductrics);
  }

  async componentDidUpdate(prevProps) {
    if (this.state.isRedirecting) {
      return;
    }

    await this.initializeAnalytics();

    if (this.props.splits[splitsList.protectWebRollout] !== null && this.state.isECDInitialized) {
      protectOptimisationECD(String(this.props.splits[splitsList.protectWebRollout]));
    }

    const { conductrics } = this.props;

    if (this.props.isPaymentDetailsView) {
      this.scrollToPaymentInfo();
    }

    if (!isEqual(prevProps.conductrics, conductrics)) {
      homeAATest(conductrics);
    }
  }

  componentWillUnmount() {
    this.props.resetChallenge();
  }

  scrollToPaymentInfo = () => {
    if (this.paymentDetailsSectionRef?.current !== null) {
      if (!this.state.scrolling) {
        this.setState({ scrolling: true });
        this.paymentDetailsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        this.props.replace('/');
      }
    }
  };

  async initializeAnalytics() {
    if (!isServer() && this.props.commonEcdData && !this.state.isECDInitialized) {
      this.setState(state => ({ ...state, isECDInitialized: true }));

      const { currentSubscription, billing } = this.props;
      const { eligibleForTrial } = this.props.commonEcdData.customer;
      const { hasDeferredActivation, productMovingTo, daysUntilExpiry } = getBreachDeferredStatus(
        currentSubscription,
        billing
      );

      const isBreachOrBreachPlus = [products.breach, products.breachPlus].includes(currentSubscription.family);

      const deferrmentInformation = {
        isDeferred: hasDeferredActivation,
        subscriptionSwitchedTo: productMovingTo,
        expiresIn: daysUntilExpiry,
        eligibleForTrial,
      };

      await Analytics.init(
        {
          ...this.props.commonEcdData,
          deferrmentInformation: isBreachOrBreachPlus ? deferrmentInformation : null,
        },
        'ProdMoveHomePage'
      );
    }
  }

  render() {
    const {
      currentSubscription,
      currentOffer,
      customer,
      offers,
      productDetails,
      billing,
      shouldShowBreachRetainingUI,
      breachExtensionVoucher,
      newBillingProvider,
    } = this.props;

    const steps = [{ text: 'Subscriptions', url: '/' }];

    const { customerNumber } = customer;
    const { defermentInfo } = currentSubscription;
    const { challengeUrl, endDate, freeTrialTerms } = billing;
    const { billingDateToday, cardSavePermitted, switchPermitted } = switchInfo(billing, currentSubscription);
    const { isLessThan30DaysToExpiry, hasDeferredActivation } = getBreachDeferredStatus(currentSubscription, billing);

    const shouldShowBreachRetentionComponent =
      shouldShowBreachRetainingUI && isLessThan30DaysToExpiry && !hasDeferredActivation;
    const shouldShowBreachDeferredSubscriptionBanner = shouldShowBreachRetainingUI && hasDeferredActivation;

    const defermentMessage = getDefermentInfo(defermentInfo);

    const freeTrialData = getFormattedFreeTrialData(currentSubscription, freeTrialTerms);

    const vitalDataLoaded = !!offers.length && currentSubscription.id;
    const customerDataLoaded = !!customer.customerId;
    const switchErrorMessage = [];

    if (billingDateToday) {
      switchErrorMessage.push(errorMessages.subscriptions.billingDate);
      const billingDateErrorEvent = MilestoneInputEvent.fromObject({
        error: {
          application: 'prodmove',
          location: 'landing page',
          type: 'billingDateError',
        },
      });

      if (this.state.isECDInitialized) {
        Analytics.publishOnce(billingDateErrorEvent);
      }
    } else if (defermentMessage) {
      switchErrorMessage.push(defermentMessage);
    }

    let switchErrorHeading = getDynamicString('changeFailure.intro', ['subscription']);
    if (defermentInfo && defermentInfo.type === subscriptionActions.cancellation) {
      switchErrorHeading = getDynamicString('changeFailure.cancellation');
    }

    if (!billingDateToday && !switchPermitted) {
      const defermentErrorEvent = MilestoneInputEvent.fromObject({
        error: {
          application: 'prodmove',
          location: 'landing page',
          type: 'defermentError',
        },
      });

      if (this.state.isECDInitialized) {
        Analytics.publishOnce(defermentErrorEvent);
      }
    }

    const availableBreachOffers = offers.filter(o => [products.breach, products.breachPlus].includes(o.family));

    const canChangePaymentMethod =
      ![products.basic, products.breach, products.breachPlus].includes(currentSubscription.family) && cardSavePermitted;

    return (
      <Container as="main" id="main">
        <TopContainer steps={steps} customerNumber={customerNumber} currentSubscription={currentSubscription.family} />
        <Heading>Your subscriptions</Heading>
        <Errors filter={Home.withoutCardErrors} {...setAutomationElement('homeError')} />
        {!switchPermitted && switchErrorMessage.length > 0 && (
          <ErrorAlert heading={switchErrorHeading} messages={switchErrorMessage} />
        )}
        {breachExtensionVoucher && !this.props.loading && (
          <InViewComponent label="subscription_ending_banner">
            <BreachExtensionSuccess endDate={endDate} />
          </InViewComponent>
        )}
        {shouldShowBreachRetentionComponent && (
          <InViewComponent label="subscription_ending_banner">
            <BreachRetentionAlert currentFamily={currentSubscription.family} />
          </InViewComponent>
        )}
        {shouldShowBreachDeferredSubscriptionBanner && (
          <InViewComponent label="change_subscription_banner">
            <BreachDeferredSubscriptionBanner breachDefermentInfo={currentSubscription.breachDefermentInfo} />
          </InViewComponent>
        )}
        {vitalDataLoaded && (
          <React.Fragment>
            <ProductPanels
              currentSubscription={currentSubscription}
              offers={productDetails.isBreach ? availableBreachOffers : offers}
              switchPermitted={switchPermitted && customerDataLoaded}
              freeTrial={freeTrialData}
              isComplimentary={productDetails.isBreach}
              productIdentifier={productDetails.productIdentifier}
              shouldShowBreachRetentionComponent={shouldShowBreachRetentionComponent}
            />
            {shouldShowBreachRetentionComponent && (
              <BreachUpsell currentSubscription={currentSubscription} offers={offers} />
            )}
            {canChangePaymentMethod && newBillingProvider && (
              <div ref={this.paymentDetailsSectionRef}>
                <PaymentOrchestrator upgradeOffer={currentOffer} methodUpdateOnly />
              </div>
            )}
            {/* The implementation below can be removed once the billing migration is complete */}
            {canChangePaymentMethod && !newBillingProvider && (
              <div ref={this.paymentDetailsSectionRef}>
                {!challengeUrl && (
                  <AccountPaymentInformationSection newCardFormName={newCardFormName} loading={this.props.loading} />
                )}
                {this.props.showCardForm && challengeUrl && <AddCardChallenge challengeUrl={challengeUrl} />}
              </div>
            )}
          </React.Fragment>
        )}
        {vitalDataLoaded && switchPermitted && (
          <InViewComponent label="deactivate_your_account">
            <DeactivateAccount />
          </InViewComponent>
        )}
      </Container>
    );
  }
}
