import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { TitleSmall500, TitleXSmall500, BodyLarge400, BodyMedium400 } from '@nebula/global-styles';
import { TextLinkInline } from '@nebula/text-link';
import { useMediaQuery } from '@nebula/hooks';
import { BaseLozengeBadge, badgeSizeEnum } from '@nebula/badge';
import { isMobileApp, openInternalBrowser } from '@experian-uk/web-common-mobile-view-helpers';

import { getEnv } from '../../Context/env';
import { TrialDuration } from '../constants';
import useBillingChangeInfo from '../hooks/useBillingChangeInfo';
import { PaymentContext } from '../../PaymentOrchestrator/PaymentContextProvider';
import { Summary, Product, Divider } from './index.styles';

const handleClick = (e, url) => {
  if (isMobileApp()) {
    e.preventDefault();
    openInternalBrowser(new URL(url));
  }
};

const TextLink = ({ url, text }) => (
  <TextLinkInline href={url} target="_blank" onClick={e => handleClick(e, url)}>
    {text}
  </TextLinkInline>
);

TextLink.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const PaymentReview = () => {
  const theme = useContext(ThemeContext);
  const env = getEnv();
  const { isTrial, upgradeOffer } = useContext(PaymentContext);

  const { billingStartDate, periodicity, price, remainingTimeUntilDate } = useBillingChangeInfo({
    upgradeOffer,
    isTrial,
  });
  const formattedStartDate = billingStartDate.format('DD MMMM YYYY');
  const formattedRemainingTimeDate = remainingTimeUntilDate?.format('DD MMMM YYYY');

  const isDesktop = useMediaQuery(theme.media.smallDesktopMin);
  const TermsAndConditions = isDesktop ? BodyLarge400 : BodyMedium400;
  const ProductLabel = isDesktop ? TitleSmall500 : TitleXSmall500;

  return (
    <Summary>
      <div>
        {isTrial && (
          <React.Fragment>
            <BodyMedium400>Starting {remainingTimeUntilDate ? formattedRemainingTimeDate : 'today'}</BodyMedium400>
            <Product>
              <ProductLabel>{TrialDuration} day trial</ProductLabel>
              <BaseLozengeBadge
                badgeSize={badgeSizeEnum.medium}
                backgroundColour={theme.color.ui.white}
                iconColour={theme.color.ui.white}
                text="No charge"
                textColour={theme.color.ui.grey[90]}
              />
            </Product>
            <Divider />
          </React.Fragment>
        )}
        <BodyMedium400>Starting on {formattedStartDate}</BodyMedium400>
        <Product>
          {periodicity && <ProductLabel>{periodicity} Subscription</ProductLabel>}
          <ProductLabel>£{price}</ProductLabel>
        </Product>
      </div>
      <TermsAndConditions>
        When you upgrade, you also agree to our{' '}
        <TextLink url={`${env.REACT_APP_EXPERIAN_URL}/consumer/terms.html`} text="Terms & Conditions" />. We collect and
        use your data in line with our{' '}
        <TextLink url={`${env.REACT_APP_EXPERIAN_URL}/consumer/privacy.html`} text="Privacy Policy" />. Cancel anytime.
      </TermsAndConditions>
    </Summary>
  );
};

export default PaymentReview;
