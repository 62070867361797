import { getPaymentMethods } from '../../../actions/billing/paymentMethods';
import { trackPaymentMethodChangeFinished } from '../../../helpers/switch/analytics';
import { PAYMENT_ERROR_GROUPS, PAYMENT_ERROR_TYPES } from '../constants/paymentErrors';
import updatePaymentMethod from '../helpers/updatePaymentMethod';
import { PAYMENT_STAGES } from '../PaymentContextProvider';

const handleSuccessfulPurchase = async (paymentContext, paymentChangeType, dispatch) => {
  trackPaymentMethodChangeFinished({ updateStatus: 'payment_updated', attemptedUpdateMethod: paymentChangeType });

  const { setPaymentChangeActive, clearPaymentErrors, goToPaymentStage } = paymentContext;

  await getPaymentMethods()(dispatch);
  setPaymentChangeActive(false);
  clearPaymentErrors();
  goToPaymentStage(PAYMENT_STAGES.PAYMENT_METHOD_UPDATED);
};

/*
  Responsible for progressing past payment token capture when a customer already has a stored payment method.
  ApplePay will progress directly to success or failure - no 3DS required.
*/
const paymentMethodChangeTransition =
  (paymentContext, methodToken, paymentChange, runBeforeTransition) => async dispatch => {
    const { addPaymentToken, goToPaymentStage, raisePaymentError, restartPaymentFlow, setPaymentChangeType } =
      paymentContext;

    addPaymentToken({ paymentMethod: methodToken });
    setPaymentChangeType(paymentChange);

    const { complete, threeDSecureActionTokenId, error } = await dispatch(
      updatePaymentMethod({
        paymentMethodToken: methodToken,
      })
    );

    await runBeforeTransition?.();

    if (complete) {
      handleSuccessfulPurchase(paymentContext, paymentChange, dispatch);
    } else if (threeDSecureActionTokenId) {
      addPaymentToken({ authorizationInput: threeDSecureActionTokenId });
      goToPaymentStage(PAYMENT_STAGES.REQUIRES_AUTHORIZATION);
    } else {
      trackPaymentMethodChangeFinished({
        updateStatus: 'payment_update_failed',
        attemptedUpdateMethod: paymentChange,
      });
      raisePaymentError({
        message: error,
        group: PAYMENT_ERROR_GROUPS.METHOD_CHANGE,
        type: PAYMENT_ERROR_TYPES.METHOD_CHANGE.UNSUCCESSFUL,
      });
      restartPaymentFlow();
    }
  };

export default paymentMethodChangeTransition;
