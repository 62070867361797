import fetch from '../../../actions/fetch';
import { ERROR_DEBUG_MESSAGES } from '../constants/paymentErrors';

const PURCHASE_ENDPOINT = '/billingsubscriptions/account';
const THREE_DS_REQUIRED_STATUS_CODE = 422;

const getPaymentAuthorizationToken = errorResponseBody =>
  errorResponseBody?.status === THREE_DS_REQUIRED_STATUS_CODE
    ? errorResponseBody.errorResponse?.PaymentAuthorizationRequestToken
    : null;

const startPurchase =
  ({ targetOfferId, paymentMethodToken, authorizationToken, isAddingPaymentMethod }) =>
  async dispatch => {
    const requestBody = {
      method: 'PUT',
      body: {
        offerId: targetOfferId,
        paymentAuthorizationResultToken: authorizationToken,
        changeCurrentPaymentMethod: false,
        paymentMethodToken,
        isAddingPaymentMethod,
      },
    };

    try {
      await dispatch(fetch(PURCHASE_ENDPOINT, requestBody));

      return {
        complete: true,
        error: null,
        threeDSecureActionTokenId: null,
      };
    } catch (paymentErrorResponse) {
      const errorResponseBody = await paymentErrorResponse?.json?.().catch(() => ({}));
      const paymentAuthorizationToken = getPaymentAuthorizationToken(errorResponseBody);

      if (paymentAuthorizationToken) {
        return {
          complete: false,
          error: null,
          threeDSecureActionTokenId: paymentAuthorizationToken,
        };
      }

      return {
        complete: false,
        error: ERROR_DEBUG_MESSAGES.PAYMENT.NON_3DS_ERROR,
        threeDSecureActionTokenId: null,
      };
    }
  };

export default startPurchase;
