import {
  FETCH_PRO_RATA_PRICE,
  PRO_RATA_PRICE_AVAILABLE,
  PRO_RATA_PRICE_NOT_AVAILABLE,
  CLEAR_PRO_RATA,
} from '../actions/billing/proRataPrice';

const initialState = {
  estimatedPrice: null,
  error: null,
  loading: null,
};

const proRataPrice = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_PRO_RATA_PRICE:
      return {
        ...state,
        loading: true,
      };
    case PRO_RATA_PRICE_AVAILABLE:
      return {
        ...state,
        loading: false,
        estimatedPrice: action.payload.estimatedPrice,
        error: null,
      };
    case PRO_RATA_PRICE_NOT_AVAILABLE:
      return {
        ...state,
        loading: false,
        estimatedPrice: null,
        error: action.payload,
      };
    case CLEAR_PRO_RATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default proRataPrice;
