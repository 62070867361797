import { PaymentMethodTypes } from '../../PaymentCaptureMethod/constants';

const getAvailablePaymentMethods = () => {
  const paymentMethods = [PaymentMethodTypes.Card];

  if (typeof window !== 'undefined' && !!window?.ApplePaySession?.canMakePayments?.()) {
    paymentMethods.push(PaymentMethodTypes.ApplePay);
  }

  return paymentMethods;
};

export default getAvailablePaymentMethods;
