import { useEffect, useRef } from 'react';
import { useRecurly } from '@recurly/react-recurly';

import { FamilyName, TrialDuration } from '../constants';

/**
 * Custom hook to handle Apple Pay integration with Recurly.
 *
 * @param {HTMLElement} applePayButton - The Apple Pay button element.
 * @param {Object} billingDetails - The billing details for the payment.
 * @param {boolean} billingDetails.isMethodChange - Indicates if the payment method is being changed.
 * @param {Object} billingDetails.upgradeOffer - The upgrade offer details.
 * @param {boolean} billingDetails.isTrial - Indicates if the payment is for a trial period.
 * @param {string} billingDetails.price - The price of the subscription.
 * @param {string} billingDetails.proRataPrice - The pro-rata price for the subscription.
 * @param {boolean} billingDetails.isProRataMovement - Indicates if the payment is a pro-rata movement.
 * @param {moment.Moment} billingDetails.billingStartDate - The start date of the billing period.
 * @param {moment.Moment} billingDetails.nextBillingDate - The next billing date.
 * @param {moment.Moment} billingDetails.remainingTimeUntilDate - The remaining time until the next billing date.
 * @param {Function} onSuccess - Callback function to be called on successful payment authorization.
 * @param {Function} onError - Callback function to be called on payment authorization error.
 *
 * @returns {Object} The Apple Pay configuration object.
 */

const useApplePay = (applePayButton, billingDetails, onSuccess, onError) => {
  const {
    isMethodChange,
    upgradeOffer,
    isTrial,
    price,
    proRataPrice,
    isProRataMovement,
    billingStartDate,
    nextBillingDate,
    remainingTimeUntilDate,
  } = billingDetails;

  const recurly = useRecurly();
  const applePayReady = useRef(false);

  const nextBill = nextBillingDate?.format('DD MMMM YYYY');
  const subscriptionStart = billingStartDate.format('DD MMMM YYYY');
  const delayedStartDate = remainingTimeUntilDate?.format('DD MMMM YYYY');

  const isApplePayButtonReady = applePayButton && !applePayReady.current;

  const regularBilling = {
    label: FamilyName[upgradeOffer.family],
    amount: price,
    paymentTiming: 'recurring',
    recurringPaymentIntervalUnit: 'month',
    recurringPaymentIntervalCount: 1,
    recurringPaymentStartDate: subscriptionStart,
  };

  const trialBilling = {
    label: `Free ${TrialDuration} Day Trial`,
    amount: '0.00',
    paymentTiming: 'recurring',
    recurringPaymentIntervalUnit: 'day',
    recurringPaymentIntervalCount: TrialDuration,
    recurringPaymentEndDate: subscriptionStart,
    ...(delayedStartDate && { recurringPaymentStartDate: delayedStartDate }),
  };

  const proRata = {
    label: 'Remaining balance you will pay today:',
    amount: proRataPrice,
    paymentTiming: 'immediate',
    type: 'final',
  };

  const methodChangeBilling = {
    label: FamilyName[upgradeOffer.family],
    amount: price,
    paymentTiming: 'recurring',
    recurringPaymentStartDate: nextBill,
  };

  const paymentDescription = FamilyName[upgradeOffer.family];

  const paymentRequest = isMethodChange
    ? {
        total: {
          label: 'Experian',
          amount: price,
        },
        recurringPaymentRequest: {
          paymentDescription,
          regularBilling: methodChangeBilling,
        },
      }
    : {
        recurringPaymentRequest: {
          paymentDescription,
          regularBilling,
          ...(isTrial && { trialBilling }),
        },
        ...(isProRataMovement && { lineItems: [proRata] }),
      };

  const applePayConfig = {
    country: 'GB',
    currency: 'GBP',
    label: 'Experian',
    total: isTrial ? 0 : price,
    recurring: true,
    paymentRequest,
    callbacks: {
      onPaymentAuthorized: ({ payment }) => onSuccess(payment.recurlyToken, 'new_apple_pay'),
    },
  };

  useEffect(() => {
    if (!isApplePayButtonReady) return undefined;

    const applePay = recurly.ApplePay(applePayConfig);

    const handleClick = () => applePay.begin();

    applePay.ready(() => {
      if (!applePayReady.current) {
        applePayButton.addEventListener('click', handleClick);
        applePayReady.current = true;
      }
    });

    applePay.on('error', err => onError(err, 'new_apple_pay'));

    return () => {
      applePayButton.removeEventListener('click', handleClick);
      applePayReady.current = false;
    };
  }, [isApplePayButtonReady, applePayButton, applePayConfig]);

  return applePayConfig;
};

export default useApplePay;
