import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Spinner from '@nebula/spinner';
import { ThreeDsContainer, ThreeDSFrame } from './index.styles';
import AutomationAuthStub from './AutomationStub';
import { getEnv } from '../../Context/env';

const AuthorizationPrompt = ({ actionTokenId, onToken, onError }) => {
  const env = getEnv();
  const shouldMockAuth = env.REACT_APP_ENV !== 'prod' && window.Cypress?.isIntegrationTest;

  const [showProcessingSpinner, setShowProcessingSpinner] = useState(false);
  const mutationRef = useRef();

  const onElementChange = mutationRecords => {
    const authFrameRemoved = mutationRecords.some(mutationRecord => {
      if (!mutationRecord.removedNodes?.length) return false;

      const iframeRemoved = Array.from(mutationRecord.removedNodes).some(node => node.nodeName === 'IFRAME');
      return iframeRemoved;
    });

    if (authFrameRemoved) {
      setShowProcessingSpinner(true);
    }
  };

  useEffect(() => {
    if (mutationRef.current) {
      const observer = new MutationObserver(onElementChange);
      observer.observe(mutationRef.current, {
        childList: true,
        subtree: true,
      });
      return () => observer.disconnect();
    }
    return () => {};
  }, [onElementChange]);

  if (shouldMockAuth) {
    return <AutomationAuthStub onToken={onToken} onError={onError} />;
  }

  return (
    <ThreeDsContainer ref={mutationRef}>
      {showProcessingSpinner && <Spinner />}
      <ThreeDSFrame
        processingResult={showProcessingSpinner}
        actionTokenId={actionTokenId}
        onToken={onToken}
        onError={onError}
      />
    </ThreeDsContainer>
  );
};

AuthorizationPrompt.propTypes = {
  actionTokenId: PropTypes.string.isRequired,
  onToken: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default AuthorizationPrompt;
