import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { TitleLarge500, TitleXLarge500 } from '@nebula/global-styles';
import { useMediaQuery } from '@nebula/hooks';

import { PaymentContext } from '../../PaymentOrchestrator/PaymentContextProvider';
import HeadingText from './HeadingText';
import SubHeading from './SubHeading';

const PaymentHeading = () => {
  const theme = useContext(ThemeContext);
  const { upgradeOffer, isTrial } = useContext(PaymentContext);
  const isDesktop = useMediaQuery(theme.media.smallDesktopMin);
  const Heading = isDesktop ? TitleXLarge500 : TitleLarge500;

  return (
    <React.Fragment>
      <Heading as="h1">
        <HeadingText isTrial={isTrial} familyType={upgradeOffer.family} />
      </Heading>
      <SubHeading isTrial={isTrial} upgradeOffer={upgradeOffer} />
    </React.Fragment>
  );
};

export default PaymentHeading;
