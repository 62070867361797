import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import styled from 'styled-components';
import { TextLinkBack } from '@nebula/text-link';

import { Column, Padding } from '../../../components/PaymentCaptureMethod/index.styles';
import { trackSwitchBackToSubscriptionsClick } from '../../../helpers/switch/analytics';

const LinkBlock = styled.p`
  ${Column};
  ${Padding};
  margin: 2.5rem 0 1rem;
`;

const ReturnToSubscriptions = () => {
  const dispatch = useDispatch();

  return (
    <LinkBlock>
      <TextLinkBack
        asButton
        onClick={() => {
          trackSwitchBackToSubscriptionsClick();
          dispatch(push('/'));
        }}
      >
        Back to your subscriptions
      </TextLinkBack>
    </LinkBlock>
  );
};

export default ReturnToSubscriptions;
