import { push } from 'connected-react-router';
import { isMobileApp, upsellTransition, UpsellTransitionStages } from '@experian-uk/web-common-mobile-view-helpers';

import { relativeUrls } from '../../../constants';
import { PAYMENT_ERROR_GROUPS, PAYMENT_ERROR_TYPES } from '../constants/paymentErrors';
import confirmSelect from '../../../actions/offers/confirmSelect';

const paymentCompleteTransition =
  (paymentContext, fulfillmentStatus, productFamilyDuringPurchase) => async dispatch => {
    const { raisePaymentError, restartPaymentFlow, upgradeOffer, isTrial } = paymentContext;
    const upgradeOfferPrice = upgradeOffer.terms?.[0]?.price;

    if (fulfillmentStatus === true) {
      const selectionData = {
        cancel: false,
        complimentary: false,
        family: upgradeOffer.family,
        price: upgradeOfferPrice,
        freeTrial: isTrial,
        oldProduct: productFamilyDuringPurchase,
      };

      confirmSelect(selectionData)(dispatch);

      // Purchase successful, we should now be able to make a change to the customers subscription
      if (isMobileApp()) {
        upsellTransition({ stage: UpsellTransitionStages.SUCCESS });
      } else {
        dispatch(push(relativeUrls.thanks));
      }
      return true;
    }

    raisePaymentError({
      message: 'UNSUCCESSFUL_FULFILLMENT_ATTEMPT',
      group: PAYMENT_ERROR_GROUPS.FULFILLMENT,
      type: PAYMENT_ERROR_TYPES.FULFILLMENT.INITIATING_FULFILLMENT,
    });
    restartPaymentFlow();

    return false;
  };

export default paymentCompleteTransition;
