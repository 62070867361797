import styled from 'styled-components';
import { ThreeDSecureAction } from '@recurly/react-recurly';

export const ThreeDsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.ui.white};

  border: 1px solid ${({ theme }) => theme.color.ui.grey[20]};
  border-radius: 0.75rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  padding: 16px;
  height: 43.75rem;
  width: 100%;
`;

export const ThreeDSFrame = styled(ThreeDSecureAction)`
  display: ${({ processingResult }) => (processingResult ? 'none' : 'block')};
  height: 100%;
  width: 100%;
`;
