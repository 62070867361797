import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { PaymentMethodTypes } from '../constants';

/**
 * Custom hook to retrieve existing payment method details from the Redux store.
 *
 * @returns {Object} An object containing the existing payment type and details.
 * @returns {string|null} return.existingPaymentType - The type of the existing payment method, or null if no card is available.
 * @returns {Object} return.existingPaymentDetails - An object containing details of the existing payment method.
 * @returns {string} return.existingPaymentDetails.creditCardType - The type of the credit card (e.g., Visa, MasterCard).
 * @returns {string} return.existingPaymentDetails.cardNumber - The last four digits of the credit card number.
 * @returns {string} return.existingPaymentDetails.cardExpiryDate - The expiration date of the credit card in MM/YY format.
 * @returns {string} return.existingPaymentDetails.cardExpiryDateLong - The expiration date of the credit card in "MMMM YYYY" format.
 *
 * @example
 * const { existingPaymentType, existingPaymentDetails } = useExistingPaymentMethod();
 * if (existingPaymentType) {
 *   console.log(`Payment Type: ${existingPaymentType}`);
 *   console.log(`Card Type: ${existingPaymentDetails.creditCardType}`);
 *   console.log(`Card Number: ${existingPaymentDetails.cardNumber}`);
 *   console.log(`Expiry Date: ${existingPaymentDetails.cardExpiryDate}`);
 *   console.log(`Expiry Date (Long): ${existingPaymentDetails.cardExpiryDateLong}`);
 * } else {
 *   console.log('No existing payment method available.');
 * }
 */

const useExistingPaymentMethod = () => {
  const { creditCardType, creditCardNumber, expirationMonth, expirationYear } = useSelector(
    state => state.billing?.card ?? {}
  );

  const isCardAvailable = useSelector(state => {
    const card = state.billing?.card;
    return card && Object.keys(card).length > 0;
  });

  const isApplePayAvailable = useSelector(state => state.billing?.mobile?.paymentType === 'ApplePay');

  let existingPaymentType = null;
  if (isCardAvailable) {
    existingPaymentType = PaymentMethodTypes.Card;
  } else if (isApplePayAvailable) {
    existingPaymentType = PaymentMethodTypes.ApplePay;
  }

  const cardNumber = creditCardNumber?.slice(-4);
  const cardExpiryDate = `${String(expirationMonth).padStart(2, '0')}/${String(expirationYear).slice(-2)}`;
  const cardExpiryDateLong = dayjs(new Date(expirationYear, expirationMonth - 1)).format('MMMM YYYY');

  return {
    existingPaymentType,
    existingPaymentDetails: {
      creditCardType,
      cardNumber,
      cardExpiryDate,
      cardExpiryDateLong,
    },
  };
};

export default useExistingPaymentMethod;
