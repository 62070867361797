import { useState } from 'react';

// For use with PaymentContext, wraps up state which is used to influence payment method change
const usePaymentMethodChange = () => {
  const [paymentChangeActive, setPaymentChangeActive] = useState(false);
  const [paymentChangeType, setPaymentChangeType] = useState(null);

  return { paymentChangeActive, setPaymentChangeActive, paymentChangeType, setPaymentChangeType };
};

export default usePaymentMethodChange;
