import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { BodyLarge400, BodyLarge500, TitleSmall500 } from '@nebula/global-styles';
import { isMobileApp } from '@experian-uk/web-common-mobile-view-helpers';

import GUIDANCE_MESSAGES from '../PaymentMethodAuthorization/constants/messaging';
import { PaymentContext } from '../PaymentOrchestrator/PaymentContextProvider';
import { PAYMENT_ERROR_GROUPS, PAYMENT_ERROR_TYPES } from '../PaymentOrchestrator/constants/paymentErrors';
import AuthorizationPrompt from '../PaymentMethodAuthorization/AuthorizationPrompt';
import { Guidance, Main } from './index.styles';
import paymentChangeAuthorizedTransition from '../PaymentOrchestrator/transitions/paymentMethodChangeAuthorizedTransition';

const onGuidanceRef = node => {
  if (node) {
    node.scrollIntoView({ behavior: 'smooth' });
  }
};

const PaymentChangeAuthorization = () => {
  const paymentContext = useContext(PaymentContext);
  const { tokens, raisePaymentError, restartPaymentFlow } = paymentContext;

  const dispatch = useDispatch();

  const handleSuccess = outputToken => {
    paymentChangeAuthorizedTransition(paymentContext, outputToken.id)(dispatch);
  };

  const handleFailure = error => {
    raisePaymentError({
      message: error?.message,
      group: PAYMENT_ERROR_GROUPS.METHOD_CHANGE,
      type: PAYMENT_ERROR_TYPES.METHOD_CHANGE.UNSUCCESSFUL,
    });
    restartPaymentFlow();
  };

  return (
    <Main>
      <Guidance ref={onGuidanceRef}>
        <TitleSmall500>Confirm your card details</TitleSmall500>
        <BodyLarge400 as="span">{`${GUIDANCE_MESSAGES.HEADING} `}</BodyLarge400>
        <BodyLarge500 as="span">
          {isMobileApp() ? GUIDANCE_MESSAGES.WARNING_APP : GUIDANCE_MESSAGES.WARNING_WEB}
        </BodyLarge500>
      </Guidance>
      <div>
        <AuthorizationPrompt
          actionTokenId={tokens.authorizationInput}
          onToken={handleSuccess}
          onError={handleFailure}
        />
      </div>
    </Main>
  );
};

export default PaymentChangeAuthorization;
