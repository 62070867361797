import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CardMonthElement, CardNumberElement, CardYearElement, useRecurly } from '@recurly/react-recurly';
import { SelectArrowIcon } from '@nebula/icons/ui';

import { Element, InputState } from '../constants';
import useFocusHandler from '../hooks/useFocusHandler';
import useFormValidation from '../hooks/useFormValidation';
import useInputHandler from '../hooks/useInputHandler';
import SecurityCodeComponent from './SecurityCode';
import { DefaultCardIcon, MasterCard, Visa, CardIcons, ValidationStatusIcon } from './Icons';
import { Form, Label, CardSummary, CardNumberInput, NameInput, ExpiryDate, DateInput } from './index.styles';
import useFieldAnalytics from './hooks/useFieldAnalytics';

const PaymentCardForm = ({
  customer,
  address,
  onSuccess,
  onFailure,
  onFormValidityChange,
  onSubmitAttempt,
  innerRef,
}) => {
  const recurly = useRecurly();

  const { houseNumber, flat, houseName, street, city, postCode } = address;
  const { firstName, lastName } = customer;

  const [cardBrand, setCardBrand] = useState(null);
  const [isBrandRecognized, setIsBrandRecognized] = useState(false);
  const [inputStates, setInputStates] = useState({
    [Element.CardNumber]: InputState.Standard,
    [Element.FirstName]: InputState.Standard,
    [Element.LastName]: InputState.Standard,
    [Element.ExpiryMonth]: InputState.Standard,
    [Element.ExpiryYear]: InputState.Standard,
  });
  const [securityCodeState, setSecurityCodeState] = useState(InputState.Standard);

  const { newInputStates, handleInputChange, getRecurlyInputStyle } = useInputHandler(inputStates, setInputStates);

  const { focusedElement, handleFocus, handleBlur, focusedNestedElement, handleNestedFocus, handleNestedBlur } =
    useFocusHandler(innerRef);

  useFormValidation([newInputStates, securityCodeState], onFormValidityChange);

  useEffect(() => {
    handleInputChange(Element.FirstName, { value: firstName });
    handleInputChange(Element.LastName, { value: lastName });
  }, [firstName, lastName]);

  useFieldAnalytics(newInputStates);

  const handleCardNumberChange = ({ brand, empty, valid }) => {
    const brandMap = {
      master: <MasterCard />,
      visa: <Visa />,
    };
    setCardBrand(brandMap[brand] || <DefaultCardIcon />);
    setIsBrandRecognized(Boolean(brandMap[brand]));
    handleInputChange(Element.CardNumber, { valid, empty });
  };

  const handleNameRestriction = e => {
    if (/[\d\r\n\t\f\v§±~!@#£$%^&*()_+=[\]{};:"\\|<>/?]/g.test(e.key)) e.preventDefault();
  };

  const onFormSubmit = e => {
    e.preventDefault();
    onSubmitAttempt();
    recurly.token(innerRef.current, (err, token) => {
      if (err) {
        onFailure?.(err, 'new_card');
      } else {
        onSuccess?.(token, 'new_card');
      }
    });
  };

  return (
    <Form
      ref={innerRef}
      id="recurly-payment-form"
      data-automation-test-element="recurly-payment-form"
      onSubmit={onFormSubmit}
    >
      <input
        type="hidden"
        data-recurly="address1"
        defaultValue={`${houseNumber || flat || houseName || ''} ${street}`}
        disabled
      />
      <input type="hidden" data-recurly="city" defaultValue={city} disabled />
      <input type="hidden" data-recurly="country" defaultValue="GB" disabled />
      <input type="hidden" data-recurly="postal_code" defaultValue={postCode} disabled />

      <CardSummary>
        <Label>Card number</Label>
        {!isBrandRecognized && <CardIcons />}
      </CardSummary>
      <CardNumberInput $state={newInputStates[Element.CardNumber]} $focused={focusedElement === Element.CardNumber}>
        {cardBrand}
        <CardNumberElement
          id="card-number-input"
          onChange={handleCardNumberChange}
          onFocus={handleFocus(Element.CardNumber)}
          onBlur={handleBlur}
          style={getRecurlyInputStyle('16 digit card number')}
        />
        <ValidationStatusIcon state={newInputStates[Element.CardNumber]} />
      </CardNumberInput>

      <Label>First name on card</Label>
      <NameInput $state={newInputStates[Element.FirstName]} $focused={focusedNestedElement === Element.FirstName}>
        <input
          id="first_name"
          className="recurly-element"
          type="text"
          placeholder="Minimum of 1 character"
          data-recurly="first_name"
          defaultValue={firstName}
          onChange={e => handleInputChange(Element.FirstName, { value: e.target.value })}
          onFocus={handleNestedFocus(Element.FirstName)}
          onBlur={handleNestedBlur}
          onKeyDown={handleNameRestriction}
        />
        <ValidationStatusIcon state={newInputStates[Element.FirstName]} />
      </NameInput>

      <Label>Last name on card</Label>
      <NameInput $state={newInputStates[Element.LastName]} $focused={focusedNestedElement === Element.LastName}>
        <input
          id="last_name"
          className="recurly-element"
          type="text"
          placeholder="Minimum of 1 character"
          data-recurly="last_name"
          defaultValue={lastName}
          onChange={e => handleInputChange(Element.LastName, { value: e.target.value })}
          onFocus={handleNestedFocus(Element.LastName)}
          onBlur={handleNestedBlur}
          onKeyDown={handleNameRestriction}
        />
        <ValidationStatusIcon state={newInputStates[Element.LastName]} />
      </NameInput>

      <Label>Expiry date</Label>
      <ExpiryDate>
        <DateInput $state={newInputStates[Element.ExpiryMonth]} $focused={focusedElement === Element.ExpiryMonth}>
          <CardMonthElement
            inputType="select"
            onChange={e => handleInputChange(Element.ExpiryMonth, e)}
            onFocus={handleFocus(Element.ExpiryMonth)}
            onBlur={handleBlur}
            style={getRecurlyInputStyle('Month')}
          />
          <SelectArrowIcon />
        </DateInput>
        <DateInput $state={newInputStates[Element.ExpiryYear]} $focused={focusedElement === Element.ExpiryYear}>
          <CardYearElement
            inputType="select"
            onChange={e => handleInputChange(Element.ExpiryYear, e)}
            onFocus={handleFocus(Element.ExpiryYear)}
            onBlur={handleBlur}
            style={getRecurlyInputStyle('Year')}
          />
          <SelectArrowIcon />
        </DateInput>
      </ExpiryDate>

      <Label>Security code</Label>
      <SecurityCodeComponent
        onSecurityCodeStateChange={state => setSecurityCodeState(state)}
        onFormValidityChange={onFormValidityChange}
      />
    </Form>
  );
};

PaymentCardForm.defaultProps = {
  onSuccess: () => {},
  onFailure: () => {},
  onSubmitAttempt: () => {},
};

PaymentCardForm.propTypes = {
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  onSubmitAttempt: PropTypes.func,
  onFormValidityChange: PropTypes.func.isRequired,
  customer: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
  address: PropTypes.shape({
    houseNumber: PropTypes.string,
    flat: PropTypes.string,
    houseName: PropTypes.string,
    street: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    postCode: PropTypes.string.isRequired,
  }).isRequired,
  innerRef: PropTypes.shape({ current: PropTypes.shape({}) }).isRequired,
};

export default PaymentCardForm;
