import { BodyLarge400, BodyLarge500, TitleSmall500 } from '@nebula/global-styles';
import styled from 'styled-components';

export const Main = styled.main`
  display: grid;
  grid-gap: 1.5rem;
  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    grid-gap: 2rem;
  }
`;

export const Guidance = styled.div`
  background-color: ${({ theme }) => theme.color.decorative.sapphire[10]};
  border-radius: 0.75rem;

  ${TitleSmall500} {
    margin: 0 0 0.25rem 0;
  }

  ${BodyLarge400}, ${BodyLarge500} {
    margin: 0;
  }

  padding: 1rem;
  margin-bottom: 1.5rem;

  @media ${({ theme }) => theme.media.tabletPortraitMin} {
    margin-bottom: 2rem;
    padding: 1.5rem;
  }
`;
