import get from 'lodash/get';

import fetch from '../fetch';
import errorMessages from './errorMessages';
import { defined } from '../../helpers/defined';

export const FETCH_PRO_RATA_PRICE = 'FETCH_PRO_RATA_PRICE';
export const PRO_RATA_PRICE_FETCHED = 'PRO_RATA_PRICE_FETCHED';
export const proRataPriceEndpoint = 'billingsubscriptions/change';
export const PRO_RATA_PRICE_AVAILABLE = 'PRO_RATA_PRICE_AVAILABLE';
export const PRO_RATA_PRICE_NOT_AVAILABLE = 'PRO_RATA_PRICE_NOT_AVAILABLE';
export const CLEAR_PRO_RATA = 'CLEAR_PRO_RATA';

export default offerId => async dispatch => {
  dispatch({ type: FETCH_PRO_RATA_PRICE });

  const dispatchAction = {
    type: PRO_RATA_PRICE_FETCHED,
  };

  if (!defined('string', offerId)) {
    dispatchAction.error = true;
    dispatchAction.payload = new Error(JSON.stringify(errorMessages.proRata));

    return dispatch(dispatchAction);
  }

  try {
    const response = await dispatch(fetch(`/${proRataPriceEndpoint}/${offerId}/price`));
    const data = get(response, 'data[0]', {});
    dispatchAction.payload = data;

    // Dispatch to new reducer for PRO_RATA specifics
    if (data.estimatedPrice) {
      dispatch({
        type: PRO_RATA_PRICE_AVAILABLE,
        payload: data,
      });
    } else {
      throw new Error('missing_estimated_price');
    }
  } catch (error) {
    dispatchAction.error = true;
    dispatchAction.payload = new Error(JSON.stringify(errorMessages.proRata));

    // Dispatch to new reducer for PRO_RATA specifics
    dispatch({
      type: PRO_RATA_PRICE_NOT_AVAILABLE,
      payload: error.message ?? 'unknown_error',
    });
  }

  return dispatch(dispatchAction);
};
