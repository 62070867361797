import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { PaymentContext } from '../../PaymentOrchestrator/PaymentContextProvider';
import useBillingChangeInfo from '../hooks/useBillingChangeInfo';
import useApplePay from '../hooks/useApplePay';

const ApplePay = ({ onSuccess, onError, isMethodChange = false }) => {
  const [applePayButton, setApplePayButton] = useState(null);
  const { upgradeOffer, isTrial } = useContext(PaymentContext);
  const { billingStartDate, nextBillingDate, remainingTimeUntilDate, price, proRataPrice, isProRataMovement } =
    useBillingChangeInfo({
      upgradeOffer,
      isTrial,
    });

  const applePayRef = useCallback(node => {
    if (node !== null) {
      setApplePayButton(node);
    }
  }, []);

  const billingDetails = {
    isMethodChange,
    upgradeOffer,
    isTrial,
    price,
    proRataPrice,
    isProRataMovement,
    billingStartDate,
    nextBillingDate,
    remainingTimeUntilDate,
  };

  useApplePay(applePayButton, billingDetails, onSuccess, onError);

  return (
    <apple-pay-button
      data-automation-test-element="apple-pay-button"
      ref={applePayRef}
      id="apple-pay-btn"
      buttonstyle="black"
      type="pay"
      locale="en-GB"
    />
  );
};

ApplePay.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  isMethodChange: PropTypes.bool,
};

export default ApplePay;
