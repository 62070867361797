import { trackSwitchInitiatePurchase } from '../../../helpers/switch/analytics';
import { PAYMENT_ERROR_GROUPS, PAYMENT_ERROR_TYPES } from '../constants/paymentErrors';
import startPurchase from '../helpers/startPurchase';
import { PAYMENT_STAGES } from '../PaymentContextProvider';

/*
  Responsible for moving from the payment method capture stage to:
  - Fulfillment (Authorization has been handled elsewhere, such as with Apple Pay)
  - 3DS (Payment Authorization)
*/
const paymentMethodStoredTransition = (paymentContext, cardToken, runBeforeTransition) => async dispatch => {
  const { upgradeOffer, addPaymentToken, goToPaymentStage, raisePaymentError, restartPaymentFlow } = paymentContext;

  addPaymentToken({ paymentMethod: cardToken });

  const { complete, threeDSecureActionTokenId, error } = await dispatch(
    startPurchase({
      targetOfferId: upgradeOffer.id,
      paymentMethodToken: cardToken,
      isAddingPaymentMethod: true,
    })
  );

  await runBeforeTransition?.();

  if (complete) {
    // We do not need to authorize this payment method (mobile wallets)
    trackSwitchInitiatePurchase(true);
    goToPaymentStage(PAYMENT_STAGES.REQUIRES_FULFILLMENT);
  } else if (threeDSecureActionTokenId) {
    // Payment method requires authorization as we have received a 3DS identifier in the response
    trackSwitchInitiatePurchase(true);
    addPaymentToken({ authorizationInput: threeDSecureActionTokenId });
    goToPaymentStage(PAYMENT_STAGES.REQUIRES_AUTHORIZATION);
  } else {
    // Failure, prompt a user to try a different card
    trackSwitchInitiatePurchase(false);
    raisePaymentError({
      message: error,
      group: PAYMENT_ERROR_GROUPS.TOKENIZATION,
      type: PAYMENT_ERROR_TYPES.TOKENIZATION.INITIATING_PURCHASE,
    });
    restartPaymentFlow();
  }
};

export default paymentMethodStoredTransition;
