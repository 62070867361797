import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { trackSwitchFormAttempt } from '../../helpers/switch/analytics';
import { PAYMENT_LOG_STEPS } from '../../constants/paymentLogSteps';
import useTrackUpsellStage from '../PaymentOrchestrator/hooks/useTrackUpsellStage';
import { PaymentContext } from '../PaymentOrchestrator/PaymentContextProvider';
import paymentMethodStoredTransition from '../PaymentOrchestrator/transitions/paymentMethodStoredTransition';
import { PAYMENT_ERROR_GROUPS, PAYMENT_ERROR_TYPES } from '../PaymentOrchestrator/constants/paymentErrors';
import PaymentError from './PaymentError';
import PaymentHeading from './PaymentHeading';
import PaymentReview from './PaymentReview';
import PaymentMethodEntry from './PaymentMethodEntry';
import paymentMethodExistsTransition from '../PaymentOrchestrator/transitions/paymentMethodExistsTransition';
import { Main, Alert, Header, Review, Methods } from './index.styles';

const PaymentCaptureMethod = () => {
  const paymentContext = useContext(PaymentContext);
  const { paymentErrors, raisePaymentError, clearPaymentErrors } = paymentContext;

  const [nextStepDisabled, setNextStepDisabled] = useState(false);
  const trackUpsellStage = useTrackUpsellStage();
  const dispatch = useDispatch();

  useEffect(() => {
    if (paymentErrors.length) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [paymentErrors.length]);

  const handleSubmitAttempt = () => {
    setNextStepDisabled(true);
    clearPaymentErrors();
  };

  const handleSuccess = async (cardToken, paymentType) => {
    trackSwitchFormAttempt(true, paymentType);
    trackUpsellStage(PAYMENT_LOG_STEPS.SWITCH_FORM_COMPLETE);

    const runBeforeTransition = () => setNextStepDisabled(false);
    await paymentMethodStoredTransition(paymentContext, cardToken.id, runBeforeTransition)(dispatch);
  };

  const handleFailure = (error, paymentType) => {
    setNextStepDisabled(false);
    clearPaymentErrors();
    raisePaymentError({
      message: error.message,
      group: PAYMENT_ERROR_GROUPS.TOKENIZATION,
      type: PAYMENT_ERROR_TYPES.TOKENIZATION.GENERATING_PAYMENT_TOKEN,
    });

    trackSwitchFormAttempt(false, paymentType);
    trackUpsellStage(PAYMENT_LOG_STEPS.SWITCH_FORM_FAILED);
  };

  const handleExistingPaymentMethod = async () => {
    clearPaymentErrors();
    trackSwitchFormAttempt(true, 'existing_card');
    trackUpsellStage(PAYMENT_LOG_STEPS.SWITCH_FORM_COMPLETE);

    const runBeforeTransition = () => setNextStepDisabled(false);
    await paymentMethodExistsTransition(paymentContext, runBeforeTransition)(dispatch);
  };

  return (
    <React.Fragment>
      {paymentErrors.map(error => (
        <Alert key={error.type}>
          <PaymentError error={error} />
        </Alert>
      ))}
      <Header>
        <PaymentHeading />
      </Header>
      <Main>
        <Review>
          <PaymentReview />
        </Review>
        <Methods>
          <PaymentMethodEntry
            disabled={nextStepDisabled}
            onSubmit={handleSubmitAttempt}
            onSuccess={handleSuccess}
            onFailure={handleFailure}
            onExistingPaymentMethod={handleExistingPaymentMethod}
          />
        </Methods>
      </Main>
    </React.Fragment>
  );
};

export default PaymentCaptureMethod;
