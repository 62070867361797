import fetch from '../../../actions/fetch';
import { ERROR_DEBUG_MESSAGES } from '../constants/paymentErrors';

const CHANGE_PAYMENT_ENDPOINT = '/billingsubscriptions/paymentmethods';
const THREE_DS_REQUIRED_STATUS_CODE = 422;

const getPaymentAuthorizationToken = errorResponseBody =>
  errorResponseBody?.status === THREE_DS_REQUIRED_STATUS_CODE
    ? errorResponseBody.errorResponse?.PaymentAuthorizationRequestToken
    : null;

const updatePaymentMethod =
  ({ paymentMethodToken, authorizationToken }) =>
  async dispatch => {
    const requestBody = {
      method: 'POST',
      body: {
        paymentAuthorizationResultToken: authorizationToken,
        paymentMethodToken,
      },
    };

    try {
      await dispatch(fetch(CHANGE_PAYMENT_ENDPOINT, requestBody));

      return {
        complete: true,
        error: null,
        threeDSecureActionTokenId: null,
      };
    } catch (paymentErrorResponse) {
      const errorResponseBody = await paymentErrorResponse?.json?.().catch(() => ({}));
      const paymentAuthorizationToken = getPaymentAuthorizationToken(errorResponseBody);

      if (paymentAuthorizationToken) {
        return {
          complete: false,
          error: null,
          threeDSecureActionTokenId: paymentAuthorizationToken,
        };
      }

      return {
        complete: false,
        error: ERROR_DEBUG_MESSAGES.PAYMENT.NON_3DS_ERROR,
        threeDSecureActionTokenId: null,
      };
    }
  };

export default updatePaymentMethod;
